import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useGetCaisseLogs } from "../../api/caisses";
import { ReactComponent as UpdateIcon } from "../../assets/CRUD/update.svg";
import { ReactComponent as ViewIcon } from "../../assets/CRUD/view.svg";
import { ReactComponent as EmptySVG } from "../../assets/emptySvg.svg";
import ActionsMenu from "../../components/ActionsMenu";
import Pagination from "../../components/Pagination";
import Spinner from "../../components/ui/Spinner";
import { useAuth } from "../../contexts/Auth";
import LogConfirmPopup from "./ConfirmPopup";
import LogViewPopup from "./View";

export default function CaisseLogs(): React.JSX.Element {
  const { t } = useTranslation();
  const { selectedCaisse, permissions } = useAuth();
  const { id } = selectedCaisse || { id: "" };
  const [searchParams] = useSearchParams();

  const { isLoading, data } = useGetCaisseLogs(id);

  const [toggleOptions, setToggleOptions] = useState<number>(-1);
  const [selectedConfirmImage, setSelectedConfirmImage] = useState<string | undefined>(undefined);
  const [selectedLogId, setSelectedLogId] = useState<string | undefined>(undefined);

  const { pageCount, response: caisseStops } = data || {};
  const currentPage = Math.max(Number(searchParams.get("page")) || 1, 1);

  return (
    <article className="space-y-5">
      <div className="rounded-xl bg-white p-4">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50 *:p-3 *:text-start *:text-sm *:font-semibold">
              <th>{t("cashRegisterName")}</th>
              <th>{t("cashRegisterNumber")}</th>
              <th>{t("versement")}</th>
              <th>{t("stopDate")}</th>
              <th>{t("confirmationDate")}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={6}>
                  <div className="flex h-96 items-center justify-center">
                    <Spinner />
                  </div>
                </td>
              </tr>
            ) : caisseStops && caisseStops.length > 0 ? (
              caisseStops.map((caisseStop, index) => (
                <tr
                  key={caisseStop.logId}
                  className="*:p-3 *:font-medium odd:bg-white even:bg-gray-50"
                >
                  <td>{caisseStop.registerName}</td>
                  <td>{caisseStop.registerNumber}</td>
                  <td>{caisseStop.logTransferType}</td>
                  <td>{new Date(caisseStop.logDate).toLocaleDateString("fr-FR")}</td>
                  <td>
                    {caisseStop.confirmationDate ? new Date(caisseStop.logDate).toLocaleDateString("fr-FR") : t("N/A")}
                  </td>
                  <td>
                    <ActionsMenu
                      elementIndex={index}
                      setToggleOptions={setToggleOptions}
                      toggleOptions={toggleOptions}
                      actions={[
                        {
                          permissions: caisseStop.confirmationDate ? permissions?.registerConfirmLog || "" : "",
                          requiredPermissions: "R",
                          title: "view",
                          icon: ViewIcon,
                          className: "text-primaryGreen hover:text-white hover:bg-primaryGreen duration-150",
                          handleAction: () => {
                            if (!caisseStop?.confirmationFileLink) return;

                            setSelectedConfirmImage(caisseStop.confirmationFileLink);
                            return;
                          },
                        },
                        {
                          permissions: !caisseStop.confirmationDate ? permissions?.registerConfirmLog || "" : "",
                          requiredPermissions: "C",
                          title: "confirm",
                          icon: UpdateIcon,
                          className: "text-orange-500 hover:text-white hover:bg-orange-500 duration-150",
                          handleAction: () => {
                            setSelectedLogId(caisseStop.logId);
                            return;
                          },
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className="flex h-96 items-center justify-center">
                    <EmptySVG />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {pageCount && pageCount > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={pageCount}
        />
      )}

      {selectedLogId && (
        <LogConfirmPopup
          logId={selectedLogId}
          onClose={() => setSelectedLogId(undefined)}
        />
      )}

      {selectedConfirmImage && (
        <LogViewPopup
          image={selectedConfirmImage}
          onClose={() => setSelectedConfirmImage(undefined)}
        />
      )}
    </article>
  );
}
