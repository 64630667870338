import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronDownIcon } from "../assets/icons/chevronDown.svg";
import { BANKS } from "../constants/banks";
import { cn } from "../utils";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "./ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { ReactComponent as CheckIcon } from "../assets/icons/valid.svg";

interface BankSelectorProps {
  value: string;
  setBank: (bank: string) => void;
  error?: string;
  className?: string;
}

export default function BankSelector({ value, setBank, error, className }: BankSelectorProps): React.JSX.Element {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const divRef = useRef<HTMLDivElement>(null);

  return (
    <Popover
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <PopoverTrigger className={cn("shrink-0", className)}>
        <div
          ref={divRef}
          className="flex h-full flex-col items-start justify-start gap-2"
        >
          <label className="text-base font-medium text-neutral-800">{t("bank")}</label>
          <div
            className="flex w-full cursor-pointer items-center justify-between gap-x-2.5 rounded-md border border-neutral-300 px-4 py-3 font-normal outline-none focus:border-primaryGreen"
            aria-expanded={isOpen}
          >
            <span className={cn("truncate text-sm font-semibold", value ? "" : "text-neutral-500")}>
              {value ? value : t("selectBank")}
            </span>
            <ChevronDownIcon className={cn("size-4 duration-150", isOpen ? "rotate-180" : "rotate-0")} />
          </div>
          {error && <span className="text-sm text-destructive-500">{error}</span>}
        </div>
      </PopoverTrigger>
      <PopoverContent
        style={{ width: divRef.current?.offsetWidth }}
        className="p-0"
      >
        <Command>
          <CommandInput
            placeholder={t("searchBank")}
            value={search}
            onValueChange={v => setSearch(v)}
          />
          <CommandList>
            <CommandEmpty>{t("noBankFound")}</CommandEmpty>
            <CommandGroup>
              {BANKS?.map(bank => (
                <CommandItem
                  key={bank}
                  value={bank}
                  onSelect={() => {
                    setBank(bank);
                    setIsOpen(false);
                  }}
                >
                  <CheckIcon className={cn("mr-2 h-4 w-4", value === bank ? "opacity-100" : "opacity-0")} />
                  {bank}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
