import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Spinner from "../../components/ui/Spinner";
import { LoginRequest } from "../../types/request/LoginRequest";
import { useFormik } from "formik";
import AuthInput from "./Inputs";
import { ReactComponent as EmailIcon } from "../../assets/Auth/email.svg";
import createLoginSchema from "./validator";
import { useAuth } from "../../contexts/Auth";
import { useNavigate } from "react-router-dom";
import { REGEX } from "../../constants";
import toast from "react-hot-toast";

export default function Login(): React.JSX.Element {
  const { t } = useTranslation();
  const { login, otpCode: magicLink } = useAuth();
  const { isLoading: isLoadingLogin, mutate: handleLogin } = login;
  const { isLoading: isLoadingMagicLink, mutate: handleMagicLink } = magicLink;

  const navigate = useNavigate();

  const isLoading: boolean = isLoadingLogin || isLoadingMagicLink;

  const initialValues: LoginRequest = {
    password: "",
    pseudo: "",
  };

  const formik = useFormik<LoginRequest>({
    initialValues,
    validationSchema: createLoginSchema(t),
    onSubmit: values => {
      REGEX.EMAIL.test(values.pseudo)
        ? handleMagicLink(values, {
            onSuccess() {
              toast.success(t("magicLinkSuccess"));
              navigate("/email/sent");
            },
          })
        : handleLogin(values, {
            onSuccess() {
              navigate("/dashboard");
            },
          });
    },
  });

  return (
    <main className="z-50 mx-auto w-[clamp(300px,100%,545px)] rounded-lg border bg-white px-4 py-10 shadow-lg sm:px-6">
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col gap-6"
      >
        <section className="text-center">
          <h2 className="text-xl font-bold md:text-2xl">{t("loginTitle")}</h2>
          <p className="text-sm font-normal text-primaryGray md:text-base">{t("loginDesc")}</p>
        </section>

        <AuthInput
          label="username"
          name="pseudo"
          onChange={formik.handleChange}
          placeholder="username"
          type="text"
          value={formik.values.pseudo}
          error={formik.errors.pseudo}
          Icon={EmailIcon}
        />

        <AuthInput
          label="password"
          name="password"
          onChange={formik.handleChange}
          placeholder="password"
          type="password"
          value={formik.values.password}
          error={formik.errors.password}
        />

        <PrimaryButton
          disabled={isLoading}
          className="flex items-center justify-center"
        >
          {isLoading ? <Spinner /> : t("login")}
        </PrimaryButton>
      </form>
    </main>
  );
}
