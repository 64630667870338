import { useEffect } from "react";

export default function RefetchOnChange() {
  useEffect(() => {
    if (!localStorage.getItem("token")) return;

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window?.chrome?.webview.postMessage(
        JSON.stringify({
          token: localStorage.getItem("token"),
          action: "login",
        }),
      );
    } catch (error) {
      console.error(error);
    }
  }, []);

  return null;
}
