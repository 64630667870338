import DashboardLayout from "../components/layouts/DashboardLayout";
import LoginLayout from "../components/layouts/LoginLayout";
import CaisseLogs from "../pages/Caisse";
import Handler from "../pages/Handler";
import Login from "../pages/Login";
import MagicLink from "../pages/MagicLink";
import MagicLinkSentSuccess from "../pages/MagicLink/SentSuccess";
import NoCaisse from "../pages/NoCaisse";
import NotAuthorized from "../pages/NotAuthorized";
import Profile from "../pages/Profile";
import SearchTenant from "../pages/Search";
import Stats from "../pages/Stats";
import Tenant from "../pages/Tenant";
import Tenants from "../pages/Tenants";
import { UserType } from "../types/data/user";

interface Route {
  path: string;
  component: React.ComponentType;
  parent?: string;
  requiredUserType?: UserType[];
  requiredActions?: string[];
  requiredPermissions?: string[];
}

interface ParentRoute {
  path: string;
  component?: React.ComponentType;
}

export const routes: Route[] = [
  {
    path: "/",
    component: Handler,
  },
  {
    path: "/login",
    parent: "/login",
    component: Login,
    requiredUserType: ["guest"],
  },
  {
    parent: "/dashboard",
    path: "/dashboard/",
    component: SearchTenant,
    requiredUserType: ["admin"],
    requiredPermissions: ["registerTenant"],
    requiredActions: ["R"],
  },
  {
    parent: "/dashboard",
    path: "/dashboard/tenants",
    component: Tenants,
    requiredUserType: ["admin"],
    requiredPermissions: ["registerTenant"],
    requiredActions: ["R"],
  },
  {
    parent: "/dashboard",
    path: "/dashboard/tenants/:id",
    component: Tenant,
    requiredUserType: ["admin"],
    requiredPermissions: ["registerTenant"],
    requiredActions: ["R"],
  },
  {
    parent: "/dashboard",
    path: "/dashboard/stats",
    component: Stats,
    requiredUserType: ["admin"],
    requiredPermissions: ["registerStats"],
    requiredActions: ["R"],
  },
  {
    parent: "/dashboard",
    path: "/dashboard/profile",
    component: Profile,
    requiredUserType: ["admin"],
  },
  {
    parent: "/dashboard",
    path: "/dashboard/caisse",
    component: CaisseLogs,
    requiredUserType: ["admin"],
  },
  {
    path: "/not-authorized",
    component: NotAuthorized,
  },
  {
    path: "/email",
    component: MagicLink,
  },
  {
    path: "/email/sent",
    component: MagicLinkSentSuccess,
  },
  {
    path: "/no-caisse",
    component: NoCaisse,
  },
];

export const ParentRoutes: ParentRoute[] = [
  {
    path: "/login",
    component: LoginLayout,
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
  },
];
