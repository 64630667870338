import { useTranslation } from "react-i18next";
import { TenantInfoResponseBody } from "../../types/response/TenantsResponseBody";
import { ReactComponent as ViewIcon } from "../../assets/CRUD/view.svg";
import { Link } from "react-router-dom";
import { formatCreatedAtDate } from "../../utils/formatDates";
import { ReactComponent as DeactivateIcon } from "../../assets/CRUD/deactivate.svg";
import { ReactComponent as ActivateIcon } from "../../assets/CRUD/valid.svg";

interface TableProps {
  tenants: TenantInfoResponseBody[];
}

export default function TenantTable({ tenants }: TableProps): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="rounded-xl bg-white p-4">
      <table className="w-full">
        <thead>
          <tr className="bg-gray-50 *:p-3 *:text-start *:text-sm *:font-semibold">
            <th>{t("tenantCode")}</th>
            <th>{t("fullName")}</th>
            <th colSpan={4}>{t("address")}</th>
            <th>{t("from")}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {tenants.map(tenant => (
            <tr
              key={tenant.id}
              className="*:p-3 *:font-medium odd:bg-white even:bg-gray-50"
            >
              <td className="flex items-center gap-2">
                <div role="status">
                  {tenant.isActive ? (
                    <ActivateIcon className="w-4 text-primaryGreen" />
                  ) : (
                    <DeactivateIcon className="w-4 text-primaryRed" />
                  )}
                </div>
                {tenant.tenantPublicCode}
              </td>
              <td>{tenant.fullName}</td>
              <td colSpan={4}>{tenant.address}</td>
              <td>{formatCreatedAtDate(t, tenant?.lastPaidDate)}</td>
              <td>
                <Link to={`/dashboard/tenants/${tenant.accountId}`}>
                  <ViewIcon className="text-primaryGreen" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
