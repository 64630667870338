import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { ErrorResponse, ListResponse } from "../types/response";
import { CaisseLogListResponseBody, CaisseOwnResponseBody, CaisseResponseBody } from "../types/response/CaisseResponse";
import { axiosInstance } from "./axios";
import { ENDPOINTS } from "./endpoints";
import { begin, rowsPerPage } from "../utils/rowsPerPage";
import { CaisseConfirmRequestBody, StopCaisseRequestBody } from "../types/request/CaisseRequest";
import { useAuth } from "../contexts/Auth";
import toast from "react-hot-toast";
import SubmitResponseHandler from "../components/SubmitResponseHandler";
import { useSearchParams } from "react-router-dom";

export function useGetCaisses(
  options?: Omit<UseQueryOptions<ListResponse<CaisseResponseBody>, ErrorResponse>, "queryKey" | "queryFn">,
) {
  const { workspaceId } = useAuth().selectedWorkspace || {};

  async function getCaisses() {
    try {
      const response = await axiosInstance.get(ENDPOINTS.CASH_REGISTER_GET_LIST_ENDPOINT + workspaceId, {
        params: {
          begin: begin(0, 9999),
          count: 9999,
        },
      });
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }

  return useQuery<ListResponse<CaisseResponseBody>, ErrorResponse>(["caisses", workspaceId], getCaisses, {
    ...options,
  });
}

export function useStopCaisse(
  options?: Omit<UseMutationOptions<void, ErrorResponse, StopCaisseRequestBody>, "mutationKey" | "mutationFn">,
) {
  const queryClient = useQueryClient();

  async function stopCaisse(values: StopCaisseRequestBody) {
    try {
      const response = await axiosInstance.post(ENDPOINTS.CASH_REGISTER_STOP_ENDPOINT, values);

      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }

  return useMutation<void, ErrorResponse, StopCaisseRequestBody>(stopCaisse, {
    onError: error => {
      toast.error(<SubmitResponseHandler {...error} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("frameLog");
    },
    ...options,
  });
}

export function useGetOwnCiasses(
  options?: Omit<UseQueryOptions<CaisseOwnResponseBody[], ErrorResponse>, "queryKey" | "queryFn">,
) {
  async function getOwnCaisses() {
    try {
      const response = await axiosInstance.get(ENDPOINTS.CASH_REGISTER_GET_OWN_ENDPOINT);
      return response.data.response;
    } catch (error: any) {
      throw error.response.data;
    }
  }

  return useQuery<CaisseOwnResponseBody[], ErrorResponse>(["ownCaisses"], getOwnCaisses, {
    ...options,
  });
}

export function useGetCaisseLogs(
  registerId: string,
  options?: Omit<UseQueryOptions<ListResponse<CaisseLogListResponseBody>, ErrorResponse>, "queryKey" | "queryFn">,
) {
  const [searchParams] = useSearchParams();
  const currentPage = Math.max(Number(searchParams.get("page")) || 1, 1);

  async function getLogList() {
    try {
      const response = await axiosInstance.get<ListResponse<CaisseLogListResponseBody>>(
        ENDPOINTS.CASH_REGISTER_GET_LOGS_ENDPOINT + registerId + "/log/list",
        {
          params: {
            begin: begin(currentPage, rowsPerPage),
            count: rowsPerPage,
          },
        },
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }

  return useQuery<ListResponse<CaisseLogListResponseBody>, ErrorResponse>(["frameLog", registerId], getLogList, {
    ...options,
  });
}

export function useConfirmCaisseLog(
  options?: Omit<UseMutationOptions<void, ErrorResponse, CaisseConfirmRequestBody>, "mutationKey" | "mutationFn">,
) {
  const queryClient = useQueryClient();

  async function confirmCaisseLog(values: CaisseConfirmRequestBody) {
    try {
      const formData = new FormData();
      for (const k in values) {
        const key = k as keyof CaisseConfirmRequestBody;
        const value = values[key];

        if (!value) continue;

        formData.append(key, value);
      }
      const response = await axiosInstance.post(ENDPOINTS.CASH_REGISTER_CONFIRM_ENDPOINT, formData);
      return response.data.response;
    } catch (error: any) {
      throw error.response.data;
    }
  }

  return useMutation<void, ErrorResponse, CaisseConfirmRequestBody>(confirmCaisseLog, {
    onError: error => {
      toast.error(<SubmitResponseHandler {...error} />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("frameLog");
    },
    ...options,
  });
}
