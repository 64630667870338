import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useTranslation } from "react-i18next";
import { enUS, arDZ, fr } from "react-day-picker/locale";
import AlertStats from "./Popup";
import { useFrameLog } from "../../api/stats";
import { CaisseTimeFrameLog } from "../../types/request/StatsRequest";
import { useAuth } from "../../contexts/Auth";
import { STATE_DATE_TYPE, StateDateType, STATS_TYPES, StatsTypes } from "../../constants";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

interface DaypickerProps {
  value: Date | undefined;
  error?: string;
  dateType: StateDateType;
  onChangeDate: (date: Date | undefined) => void;
  setError?: (error: string | undefined) => void;
  selectedStateType: "" | StatsTypes;
}

export default function Daypicker({
  onChangeDate,
  value,
  error,
  dateType,
  setError,
  selectedStateType,
}: DaypickerProps): React.JSX.Element {
  const { t, i18n } = useTranslation();
  const { selectedCaisse, selectedWorkspace } = useAuth();

  const ref = useRef<HTMLDivElement>(null);
  const alert = useRef<boolean>(false);
  const didChange = useRef<boolean>(false);
  const [isDayPickerOpen, setIsDayPickerOpen] = useState<boolean>(false);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [timeFrameRequest, setTimeFrameRequest] = useState<CaisseTimeFrameLog>({
    workspaceId: selectedWorkspace?.workspaceId || "",
    registryId: selectedCaisse?.id || "",
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 2)),
    endDate: new Date(new Date().setMonth(new Date().getMonth() + 2)),
  });
  const [year, setYear] = useState<string>("");

  const { data: allowedDays } = useFrameLog(timeFrameRequest, {
    onSuccess(data) {
      const allowedDays = data.map(log => {
        const date = new Date(log.date);
        date.setHours(0, 0, 0, 0);

        return date;
      });

      const yesterday = new Date();
      const today = new Date();

      today.setHours(0, 0, 0, 0);
      yesterday.setHours(0, 0, 0, 0);
      yesterday.setDate(yesterday.getDate() - 1);

      if (!didChange.current && allowedDays.some(d => d.getTime() === today.getTime())) {
        didChange.current = true;
        onChangeDate(today);
      }

      if (!didChange.current && allowedDays.some(d => d.getTime() === yesterday.getTime())) {
        didChange.current = true;
        onChangeDate(yesterday);
      }
    },
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsDayPickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const allowDays: Date[] | undefined = useMemo(() => {
    return allowedDays && allowedDays.length > 0
      ? allowedDays.map(allowDay => {
          const date = new Date(allowDay.date);
          date.setHours(0, 0, 0, 0);
          return date;
        })
      : undefined;
  }, [allowedDays]);

  const unristrictedDays: StatsTypes[] = useMemo(
    () => [STATS_TYPES.ETAT_DES_CONSTATATIONS, STATS_TYPES.JOURNAL_CAISSE],
    [],
  );

  const modifiers = useCallback(
    (date: Date) => (allowDays ?? []).some(allowDay => allowDay.toDateString() === date.toDateString()),
    [allowDays],
  );

  const handleLocale = () => {
    switch (i18n.language) {
      case "ar":
        return arDZ;
      case "en":
        return enUS;
      case "fr":
        return fr;
      default:
        return enUS;
    }
  };

  useEffect(() => {
    const yesterday = new Date();
    const today = new Date();

    today.setHours(0, 0, 0, 0);
    yesterday.setHours(0, 0, 0, 0);
    yesterday.setDate(yesterday.getDate() - 1);

    if (
      !alert.current &&
      allowDays &&
      !allowDays.some(allowDay => allowDay.getTime() === yesterday.getTime()) &&
      !allowDays.some(allowDay => allowDay.getTime() === today.getTime()) &&
      yesterday.getDay() !== 5
    ) {
      setIsAlertOpen(true);
      alert.current = true;
    }

    if (
      allowDays &&
      (allowDays.some(allowDay => allowDay.getTime() === yesterday.getTime()) ||
        allowDays.some(allowDay => allowDay.getTime() === today.getTime()))
    )
      alert.current = true;
  }, [allowDays]);

  const handleDisplayDatePicker: () => React.JSX.Element = () => {
    switch (dateType) {
      case "day":
        return (
          <DayPicker
            mode="single"
            selected={value}
            onSelect={onChangeDate}
            className={`DayPicker inset-0 z-50 box-content w-max rounded-md border bg-white px-6 pb-4 pt-2 shadow-sm ${i18n.dir() === "rtl" ? "rdp-rotate-chevron" : ""}`}
            modifiers={{
              allowdays: modifiers,
              disabled: date =>
                selectedStateType && unristrictedDays.includes(selectedStateType)
                  ? date.getTime() > new Date().setHours(0, 0, 0, 0)
                  : !modifiers(date),
            }}
            modifiersClassNames={{
              selected: "bg-primaryGreen text-white rounded-full",
              range_start: "bg-primaryGreen text-white rounded-l-full",
              range_end: "bg-primaryGreen text-white rounded-r-full",
              range_middle: "bg-primaryGreen/20",
              button_next: "text-primaryGreen!important",
            }}
            locale={handleLocale()}
            onMonthChange={month => {
              setTimeFrameRequest({
                ...timeFrameRequest,
                startDate: new Date(month.getFullYear(), month.getMonth() - 2),
                endDate: new Date(month.getFullYear(), month.getMonth() + 2),
              });
            }}
          />
        );
      case "month":
        return (
          <input
            type="month"
            className="rounded-md border border-primaryGreen px-5 py-3"
            value={year}
            onChange={e => {
              setError && setError(undefined);
              onChangeDate(new Date(e.target.value));
              setYear(e.target.value);
            }}
          />
        );
      case "year":
        return (
          <input
            type="number"
            className="rounded-md border border-primaryGreen px-5 py-3"
            value={year}
            onChange={e => {
              const yearValue = e.target.value;
              if (parseInt(yearValue) > new Date().getFullYear() || parseInt(yearValue) < 1970) {
                setError && setError(t("invalidYear"));
              } else {
                setError && setError(undefined);
                onChangeDate(new Date(yearValue));
              }

              setYear(yearValue);
            }}
          />
        );
    }
  };

  useEffect(() => {
    setYear("");
  }, [dateType]);

  useEffect(() => {
    if (
      dateType === STATE_DATE_TYPE.DAY &&
      selectedStateType &&
      !unristrictedDays.includes(selectedStateType) &&
      value &&
      !modifiers(value)
    ) {
      onChangeDate(undefined);
    }
  }, [dateType, modifiers, onChangeDate, selectedStateType, unristrictedDays, value]);

  return (
    <div
      className="relative flex w-fit items-start"
      ref={ref}
    >
      <div className="flex flex-col">
        {dateType === STATE_DATE_TYPE.DAY && (
          <>
            <PrimaryButton
              type="button"
              onClick={() => {
                setIsDayPickerOpen(!isDayPickerOpen);
              }}
              className="rounded-md bg-primaryGreen px-4 py-2 text-white"
            >
              {value ? `${t("selectedDate")} : ${value.toLocaleDateString()}` : t("selectDate")}
            </PrimaryButton>
            <span className="text-sm text-red-500">{error}</span>
          </>
        )}
      </div>
      {isDayPickerOpen && (
        <>
          {dateType === STATE_DATE_TYPE.DAY && (
            <div className="absolute inset-0 top-12">{handleDisplayDatePicker()}</div>
          )}
        </>
      )}

      {dateType !== STATE_DATE_TYPE.DAY && (
        <div className="relative flex w-fit flex-col">
          {handleDisplayDatePicker()}
          <span className="text-sm text-red-500">{error}</span>
        </div>
      )}

      {isAlertOpen && <AlertStats onClose={() => setIsAlertOpen(false)} />}
    </div>
  );
}
