import { useTranslation } from "react-i18next";
import usePopup from "../../hooks/usePopup";
import { BASE_URL } from "../../api/endpoints";

interface PopupProps {
  image: string;
  onClose: () => void;
}

export default function LogViewPopup({ image, onClose }: PopupProps): React.JSX.Element {
  usePopup();

  const { t } = useTranslation();

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-100/10">
      <div className="relative z-50 flex w-[clamp(500px,99%,1100px)] flex-col justify-end rounded-xl bg-white p-4">
        <button
          className="absolute end-4 top-3 z-50 text-lg font-bold text-gray-500 hover:text-red-500"
          onClick={onClose}
        >
          X
        </button>
        <div className="space-y-3">
          <h1 className="text-center text-xl font-semibold text-neutral-800">{t("confirmationFile")}</h1>
          <img
            src={BASE_URL + "/" + image}
            alt="Confirmation"
            className="w-full rounded-lg border"
          />
        </div>
      </div>

      <div
        className="absolute inset-0 cursor-pointer bg-slate-900 opacity-40"
        onClick={onClose}
      />
    </div>
  );
}
