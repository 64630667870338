import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/Auth";
import { useEffect } from "react";

export default function CaisseSelector(): React.JSX.Element {
  const { t } = useTranslation();

  const { setSelectedCaisse, selectedCaisse, linkedCaisse: caisses, selectedWorkspace } = useAuth();

  useEffect(() => {
    setSelectedCaisse(caisses?.filter(c => c.workspaceId === selectedWorkspace?.workspaceId)[0] || null);
  }, [caisses, selectedWorkspace, setSelectedCaisse]);

  return (
    <select
      className="h-12 w-full min-w-24 cursor-pointer rounded-lg border px-4 text-base font-bold text-primaryBlack outline-none"
      id="workspace"
      name="workspace"
      value={selectedCaisse?.id || ""}
      onChange={e => {
        const caisseId = e.target.value;
        const selected = caisses?.find(ws => ws.id === caisseId);

        if (selected) setSelectedCaisse(selected);
        else console.error("Caisse not found");
      }}
    >
      <option
        value=""
        disabled
      >
        {t("selectCashRegister")}
      </option>
      {caisses &&
        caisses
          .filter(c => c.workspaceId === selectedWorkspace?.workspaceId)
          .map(option => (
            <option
              key={option.id}
              value={option.id}
            >
              {option.name}
            </option>
          ))}
    </select>
  );
}
