import { useTranslation } from "react-i18next";
import usePopup from "../../hooks/usePopup";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { useConfirmCaisseLog } from "../../api/caisses";
import { useFormik } from "formik";
import { CaisseConfirmRequestBody } from "../../types/request/CaisseRequest";
import toast from "react-hot-toast";
import Spinner from "../../components/ui/Spinner";
import { Fragment, useState } from "react";
import { ReactComponent as ImageUpload } from "../../assets/imageUpload.svg";

interface ConfirmPopupProps {
  logId: string;
  onClose: () => void;
}

export default function LogConfirmPopup({ logId, onClose }: ConfirmPopupProps): React.JSX.Element {
  usePopup();

  const { t } = useTranslation();
  const { mutate: confirmCaisseLog, isLoading } = useConfirmCaisseLog();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [highlighted, setHighlighted] = useState<boolean>(false);

  const initialValues: CaisseConfirmRequestBody = {
    logId,
    confirmationFile: null,
  };

  const formik = useFormik<CaisseConfirmRequestBody>({
    initialValues,
    onSubmit: values => {
      confirmCaisseLog(values, {
        onSuccess: () => {
          onClose();
        },
      });
    },
  });

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-100/10">
      <form
        onSubmit={formik.handleSubmit}
        className="relative z-10 flex w-[500px] flex-col items-center gap-y-6 rounded-lg bg-white p-8 shadow-lg"
      >
        <div className="flex justify-end">
          <button
            className="absolute end-4 top-3 text-lg font-bold text-gray-500 hover:text-red-500"
            onClick={onClose}
          >
            X
          </button>
          <div className="space-y-3">
            <h1 className="text-center text-xl font-semibold text-neutral-800">{t("confirmCaisseStop")}</h1>
          </div>
        </div>
        <div className="w-full space-y-2">
          <label
            htmlFor="confirmationFile"
            className="text-neutral-800"
          >
            {t("confirmationFile")}
          </label>

          <div
            onDragOver={() => setHighlighted(true)}
            onDragLeave={() => setHighlighted(false)}
            className="relative flex h-56 w-full items-center justify-center rounded-lg border border-dashed border-primaryGreen"
          >
            <input
              type="file"
              id="confirmationFile"
              name="confirmationFile"
              accept="image/*"
              className="absolute inset-0 z-10 h-full w-full cursor-pointer opacity-0"
              onChange={e => {
                const file = e.target.files?.[0];
                if (!file) return;

                if (!file.type.includes("image")) {
                  toast.error(t("onlyImageFiles"));
                  return;
                }

                const image = new Image();
                image.src = URL.createObjectURL(file);

                image.onload = () => {
                  const reader = new FileReader();

                  reader.onload = () => {
                    setImageUrl(reader.result as string);
                    formik.setFieldValue("confirmationFile", file);
                  };

                  reader.readAsDataURL(file);
                };
              }}
            />

            {imageUrl ? (
              <Fragment>
                <button
                  type="button"
                  className="absolute -end-2 -top-2 z-50 flex size-6 items-center justify-center rounded-full bg-red-500 text-white"
                  onClick={() => {
                    setImageUrl(null);
                    formik.setFieldValue("confirmationFile", null);
                  }}
                >
                  X
                </button>
                <img
                  src={imageUrl}
                  alt="Confirmation File"
                  className="h-full w-full object-cover"
                />
              </Fragment>
            ) : (
              <div className="flex flex-col items-center gap-y-2">
                <ImageUpload />
                {highlighted ? (
                  <h1 className="text-xl text-primaryGreen">{t("release")}</h1>
                ) : (
                  <h1 className="text-xl text-primaryGray">{t("dragImage")}</h1>
                )}
                <p className="pt-1 text-sm">{"JPEG, JPG, PNG "}</p>
              </div>
            )}
          </div>
        </div>
        <section className="flex justify-center gap-4">
          <PrimaryButton
            type="button"
            onClick={onClose}
            className="!bg-destructive-500"
          >
            {t("cancel")}
          </PrimaryButton>
          <PrimaryButton
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : t("confirm")}
          </PrimaryButton>
        </section>
      </form>
      <div
        className="absolute inset-0 cursor-pointer bg-slate-900 opacity-40"
        onClick={onClose}
      />
    </div>
  );
}
