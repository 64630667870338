import React, { FunctionComponent, SVGProps, useEffect, useState } from "react";
import ActionsHandler from "./ActionsHandler";
import { ReactComponent as OptionsIcon } from "../assets/CRUD/crudToggle.svg";

export interface IActionsProps {
  actions: {
    permissions: string;
    requiredPermissions: string;
    handleAction: () => void;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    title: string;
    className?: string;
  }[];
}
interface IToggleProps {
  setToggleOptions: (index: number) => void;
  toggleOptions: number;
  elementIndex: number;
}

export default function ActionsMenu({
  actions,
  elementIndex,
  setToggleOptions,
  toggleOptions,
}: IActionsProps & IToggleProps): React.JSX.Element {
  const [toggleOn, setToggleOn] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const optionIcon = target.closest(".option-icon");
      if (!optionIcon) {
        setToggleOptions(-1);
        setToggleOn(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setToggleOptions]);

  const handleOptionClick = (index: number) => {
    if (toggleOptions === index) {
      setToggleOn(prevToggleOn => !prevToggleOn);
      setToggleOptions(-1);
    } else {
      setToggleOptions(index);
      setToggleOn(true);
    }
  };
  return (
    <div className="mx-auto w-fit">
      <button
        type="button"
        name={`action ${elementIndex}`}
        aria-label={`action ${elementIndex}`}
        className={`option-icon cursor-pointer duration-150 hover:scale-110 ${
          toggleOptions === elementIndex && toggleOn && "rotate-90 duration-75"
        }`}
        onClick={() => handleOptionClick(elementIndex)}
      >
        <OptionsIcon className="text-xl" />
      </button>
      <div className={`option-icon absolute z-50`}>
        {toggleOptions === elementIndex && toggleOn && <ActionsHandler actions={actions} />}
      </div>
    </div>
  );
}
