import { useCallback, useState } from "react";
import { useAuth } from "../../contexts/Auth";
import { useTranslation } from "react-i18next";
import ChangeWorkSpaceModal from "./ChangeWorkSpaceModal";
import { cn } from "../../utils";

export default function WorkspaceSelector(): React.JSX.Element {
  const { selectedWorkspace, linkedWorkspaces } = useAuth();
  const { t } = useTranslation();

  const [isWorkspaceModalOpen, setIsWorkspaceModalOpen] = useState<boolean>(false);

  const handleSetWorkspaceClick = useCallback((state: boolean) => {
    setIsWorkspaceModalOpen(state);
  }, []);

  return (
    <>
      <button
        className={cn(
          "text-nowrap rounded-lg text-primaryGreen underline duration-75 hover:scale-105 hover:no-underline",
          (linkedWorkspaces?.length ?? 0) > 1 ? "cursor-pointer" : "cursor-default",
        )}
        onClick={() => {
          (linkedWorkspaces?.length ?? 0) > 1 ? handleSetWorkspaceClick(true) : null;
        }}
      >
        {selectedWorkspace && selectedWorkspace?.workspaceDescription ? (
          <span className="text-center text-xs font-bold">{t(selectedWorkspace.workspaceDescription)}</span>
        ) : (
          <span className="text-center text-sm font-bold no-underline">{"/"}</span>
        )}
      </button>
      {isWorkspaceModalOpen && <ChangeWorkSpaceModal onClose={() => handleSetWorkspaceClick(false)} />}
    </>
  );
}
