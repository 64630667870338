import { IActionsProps } from "./ActionsMenu";
import React from "react";
import { useTranslation } from "react-i18next";

export default function ActionsHandler({ actions }: IActionsProps): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <div
      className={`dropdownArrow absolute -end-9 -top-1 z-50 flex h-fit w-[180px] flex-col items-start justify-center rounded-lg border border-neutral-200 bg-white shadow-lg after:absolute after:-top-1 after:end-5 after:size-2 after:rotate-45 after:border-l after:border-t after:border-l-gray-200 after:border-t-gray-200`}
    >
      {actions.map(
        (action, index) =>
          action.permissions.includes(action.requiredPermissions) && (
            <button
              key={index}
              type="button"
              className={`group flex h-[33.33px] w-[180px] items-center justify-start gap-x-2 rounded px-2 duration-150 ${
                action.className || ""
              }`}
              onClick={action.handleAction}
            >
              <action.icon className="h-4 w-4" />
              <h1 className="text-base font-medium text-current">{t(action.title)}</h1>
            </button>
          ),
      )}
    </div>
  );
}
