import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function begin(currentPage: number, rowPerPage = 10): number {
  return (currentPage - 1) * rowPerPage;
}

export function objectToFormData<T>(values: T): FormData {
  const formData = new FormData();

  for (const key in values) {
    if (!Object.prototype.hasOwnProperty.call(values, key)) continue;
    const value = values[key as keyof T];

    if (value instanceof File) formData.append(key, value);
    else if (Array.isArray(value)) {
      value.forEach(item => {
        if (item instanceof File) {
          formData.append(key, item);
        } else {
          formData.append(key, item.toString());
        }
      });
    } else if (value !== null && value !== undefined) formData.append(key, value.toString());
  }

  return formData;
}
