import { useTranslation } from "react-i18next";
import { cn } from "../../utils";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { useRef, useState } from "react";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../ui/command";
import { ReactComponent as ChevronDownIcon } from "../../assets/icons/chevronDown.svg";

interface InputComboBoxProps {
  value: string;
  setValue: (value: string) => void;
  error?: string;
  className?: string;
  placeholder: string;
  options: {
    value: string;
    label: string;
  }[];
  searchPlaceholder?: string;
  label?: string;
}

export default function InputComboBox({
  value,
  setValue,
  error,
  className,
  options,
  placeholder,
  searchPlaceholder,
  label,
}: InputComboBoxProps): React.JSX.Element {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <Popover
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <PopoverTrigger className={cn("shrink-0", className)}>
        <div
          ref={divRef}
          className="flex h-full flex-col items-start justify-start gap-2"
        >
          {label && <label className="text-base font-medium text-neutral-800">{t(label)}</label>}
          <div
            className="flex w-full cursor-pointer items-center justify-between gap-x-2.5 rounded-md border border-neutral-300 px-4 py-3 font-normal outline-none focus:border-primaryGreen"
            aria-expanded={isOpen}
          >
            <span className={cn("truncate text-sm font-semibold", value ? "" : "text-neutral-500")}>
              {value ? options.find(option => option.value === value)?.label : t(placeholder)}
            </span>
            <ChevronDownIcon className={cn("size-4 duration-150", isOpen ? "rotate-180" : "rotate-0")} />
          </div>
          {error && <span className="text-sm text-destructive-500">{error}</span>}
        </div>
      </PopoverTrigger>
      <PopoverContent
        style={{ width: divRef.current?.offsetWidth }}
        className="p-0"
      >
        <Command>
          <CommandInput
            placeholder={t(searchPlaceholder || "search")}
            value={search}
            onValueChange={v => setSearch(v)}
          />
          <CommandList>
            <CommandEmpty>{t("noDataFound")}</CommandEmpty>
            <CommandGroup>
              {options.map(option => (
                <CommandItem
                  key={option.value}
                  value={option.label}
                  onSelect={() => {
                    setValue(option.value);
                    setIsOpen(false);
                  }}
                >
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
